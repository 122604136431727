body {
  text-align: center;
  background-color: white;
  color: black;
  font-size: 20px;
  height: 100%;
}

.backgroundPic {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('./images/background.jpg');
}
.backgroundPic .headerBox {
  background-color: transparent;
}
.headerBox {
  margin: 0 auto;
  background-color: #c5cae9;
  color: black;
  width: auto;
}
ul#horizontal-list {
  list-style: none;
  margin: 0px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.navButtons {
  display: flex;
}
ul#horizontal-list li {
  display: flex;
  margin: 15px;
}

.logo {
  width: 120px;
}
.home {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh;
  font-size: 1em;
}

.homeChild {
  background: white;
  border-radius: 5px;
  padding: 7px 10px;
  border: 2px solid black;
}
.cvText {
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: flex-start;
}
.cvPdf {
  display: inline-block;
}
.heading,
.headingText {
  font-size: 60px;
  font-weight: 600;
  text-align: center;
  line-height: 1.5;
  margin: 30px 30px;
}
.heading {
  font-family: 'Titillium Web', sans-serif;
}
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@600&display=swap');
.headingText {
  font-size: 15px;
}

.github,
.linkedin {
  max-width: 40px;
  margin: 5px;
}

.one {
  height: 1000px;
}

.aboutMeBox {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.aboutMeText {
  max-width: 700px;
  text-align: left;
}

.aboutMePicture,
.aboutMeTextBox {
  padding: 30px;
}
.picture {
  width: 100%;
  object-fit: cover;
  max-width: 400px;
}

.portfolioFlex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  justify-items: center;
  align-items: center;
}
.portfolioImage {
  max-width: 400px;
  padding: 20px;
}
.igIcon {
  max-width: 60px;
  float: right;
}
.firstDiagram {
  grid-area: firstOne;
}
.secondDiagram {
  grid-area: firstTwo;
}
a {
  color: inherit;
  text-decoration: none;
}

.cvImg {
  box-shadow: 0px 0px 5px grey;
}
.myModal {
  color: black;
}
.myModal h2 {
  text-transform: capitalize;
}
.myModal .closeModal {
  font-weight: bold;
  cursor: pointer;
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 25px;
}
.portfolioImageModal {
  max-width: 600px;
}
.githubModal {
  max-width: 50px;
}
.textboxModal {
  margin: 20px;
}
.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 420px;
  height: 255.95px;
  opacity: 0;
  transition: 0.45s ease;
  background-color: black;
  cursor: pointer;
}

.overlayContainer:hover .overlay {
  opacity: 0.7;
}
.overlayContainer {
  position: relative;
}
.text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

@media only screen and (max-width: 800px) {
  .cvImg {
    max-width: 600px;
    display: inline;
  }
  .myModal img {
    max-width: 500px;
  }
  .cvPdf .react-pdf__Page__svg {
    width: 100% !important;
    height: auto !important;
  }
  .cvPdf svg {
    width: 100% !important;
    height: auto !important;
  }
  .portfolioImage {
    width: 100%;
  }
  .githubModal {
    width: 70px;
  }
}

@media only screen and (max-width: 400px) {
  .myModal img {
    max-width: 300px;
  }
  .cvPdf .react-pdf__Page__svg {
    width: 100% !important;
    height: auto !important;
  }
  .cvPdf svg {
    width: 100% !important;
    height: auto !important;
  }
  .logo {
    width: 60px;
  }
  .portfolioImage {
    width: 100%;
  }
  .githubModal {
    width: 50px;
  }
}

.paddingTop {
  margin-top: 15px;
}

.paddingLittle {
  margin: 15px;
} 